
import {
    propertyService
} from '@/api/utils/request'
const apiUrl = {
    GetGridheadList: "/api/gridhead/gets",
    CreateGridhead: "/api/gridhead/create",
    UpdateGridhead: "/api/gridhead/update",
    GetGridhead: "/api/users/get",
    DeleteGridhead:"/api/gridhead/delete"

}



const gridheadApi = {
    //获取网格长列表
    GetGridheadList(parameter) {
        return propertyService({
            url: apiUrl.GetGridheadList,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    //新建网格长
    CreateGridhead(parameter) {
        return propertyService({
            url: apiUrl.CreateGridhead,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //修改网格长
    UpdateGridhead(parameter) {
        return propertyService({
            url: apiUrl.UpdateGridhead,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //获取一个网格长
    GetGridhead(parameter) {
        return propertyService({
            url: apiUrl.GetGridhead,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    //删除一个网格长
    DeleteGridhead(parameter) {
        return propertyService({
            url: apiUrl.DeleteGridhead,
            method: 'delete',
            params: parameter,
            needToken: true
        })
    },
}

export {
    gridheadApi
}