<template>
  <div class="page-content">
    <!-- begin card -->
    <!-- <div class="card bg-grey-lighter m-b-5 col-md-12">
      <div class="card-body">
        <blockquote class="blockquote">
          <h2 class="page-header text-left text-black-lighter">网格长</h2>
        </blockquote>
      </div>
    </div> -->
    <!-- end card -->
    <div class="register-content">
      <form v-on:submit="checkForm" method="POST">
        <div class="panel-header p-20">
          <h4 class="f-w-300 col-lg-10 col-md-8">
            <i class="fas fa-fw fa-outdent text-aqua-darker"></i>
            网格长信息
          </h4>
        </div>
        <!-- panel-body begin -->
        <div class="panel-body">
          <div class="invoice  bg-light p-10 p-b-40">
          <div class="row form-group m-b-10">
            <label class="col-lg-2 col-md-3 col-form-label text-right"
              >账号：</label
            >
            <div class="col-lg-4 col-md-9">
              <div class="input-group">
                <input
                  class="form-control"
                  data-parsley-range="[5,10]"
                  v-model="Gridhead.userName"
                  required
                />
              </div>
            </div>
            <label class="col-lg-2 col-md-3 col-form-label text-right"
              >姓名：</label
            >
            <div class="col-lg-4 col-md-9">
              <div class="input-group">
                <input
                  class="form-control"
                  data-parsley-range="[5,10]"
                  v-model="Gridhead.name"
                />
              </div>
            </div>
          </div>
          <div class="row form-group m-b-10">
            <label class="col-lg-2 col-md-3 col-form-label text-right"
              >密码：</label
            >
            <div class="col-lg-4 col-md-9">
              <div class="input-group">
                <input
                  class="form-control"
                  data-parsley-range="[5,10]"
                  v-model="Gridhead.password"
                />
              </div>
            </div>
            <label class="col-lg-2 col-md-3 col-form-label text-right"
              >电话号码：</label
            >
            <div class="col-lg-4 col-md-9">
              <div class="input-group">
                <input
                  class="form-control"
                  data-parsley-range="[5,10]"
                  v-model="Gridhead.phoneNumber"
                />
              </div>
            </div>
          </div>
          <div class="row form-group m-b-10">
            <label class="col-lg-2 col-md-3 col-form-label text-right"
              >状态：</label
            >
            <div class="col-lg-4 col-md-9">
              <div class="radio radio-css radio-inline m-r-15">
                <input
                  type="radio"
                  id="typeRadio"
                  name="type"
                  v-model="Gridhead.isActive"
                  value="true"
                  checked
                />
                <label for="typeRadio" class="m-r-15"> 是</label>
                <input
                  type="radio"
                  id="typeRadio1"
                  name="type"
                  v-model="Gridhead.isActive"
                  value="false"
                />
                <label for="typeRadio1"> 否</label>
              </div>
            </div>
            <label class="col-lg-2 col-md-3 col-form-label text-right"
              >所属区域：</label
            >
            <div class="col-lg-4 col-md-9 m-b-10">
              <dictSelect
                :show="false"
                :value="Gridhead.regionId"
                @select="regionIdIdSelect"
                :pid="'3a019cfc-ef88-6b72-7bc4-f7c04aa3e94c'"
              ></dictSelect>
            </div>
          </div>
          <!-- <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >头像：</label
              >
              <div class="page-content col-lg-4 col-md-9">
                <div>
                  <upload
                    :maxSize="10"
                    @finishUpload="finish"
                    :value="Gridhead.cover"
                  >
                  </upload>
                </div>
              </div>
            </div> -->
         </div>
        </div>
        <p class="text-right p-t-10">
          <button class="btn btn-sm btn-success m-r-10" type="submit">
            保存
          </button>
          <button
            type="button"
            @click="$emit('close')"
            class="btn btn-sm btn-grey m-r-10"
          >
            关闭
          </button>
        </p>
      </form>
    </div>
  </div>
</template>
<script>
import { gridheadApi } from "@/api/systembasic/gridheadApi";
import dictSelect from "@/components/dictSelect/index.vue";
export default {
  components: {
    dictSelect,
  },
  props: ["Gridheadid"],
  data() {
    return {
      loading: false,
      Gridhead: {
        userName: "",
        name: "",
        password: "",
        phoneNumber: "",
        isActive: "",
        regionId: "",
        id: "",
      },
      id: [],
    };
  },
  mounted() {
    this.id = this.Gridheadid;
    if (this.id) {
      this.GetGridhead(this.id);
    }
  },
  methods: {
        ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "保存成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    ToastWarning(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "False") {
        title = "保存出错";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "warning",
        title: title,
      });
    },
    GetGridhead(id) {
      let that = this;
      gridheadApi
        .GetGridhead({
          id: id,
        })
        .then((res) => that.GetGridheadSuccess(that, res))
        .finally(() => {});
    },

    checkForm: function(e) {
      e.preventDefault();
      const params = {
        id: this.id,
        UserName: this.Gridhead.userName,
        Name: this.Gridhead.name,
        Password: this.Gridhead.password,
        PhoneNumber: this.Gridhead.phoneNumber,
        IsActive: this.Gridhead.isActive,
        RegionId: this.Gridhead.regionId,
      };

      //e.preventDefault();
      let that = this;
      if (that.id) {
        gridheadApi
          .UpdateGridhead(params)
          .then((res) => that.UpdateGridheadSuccess(that, res))
          .finally(() => {});
      } else {
        gridheadApi
          .CreateGridhead(params)
          .then((res) => that.CreateGridheadSuccess(that, res))
          .finally(() => {});
      }
    },

    GetGridheadSuccess(that, res) {
      if (res.success) {
        that.Gridhead = res.data;
        that.Gridhead.regionId = res.data.extraProperties.RegionId;
      } else {
        console.log(res);
      }
    },
    CreateGridheadSuccess(that, res) {
      if (res.success) {
        that.Gridhead = res.data;
        that.ToastSuccess(res);
      } else {
        that.ToastWarning(res);
        console.log(res);
      }
    },
    UpdateGridheadSuccess(that, res) {
      if (res.success) {
        that.Gridhead = res.data;
        that.ToastSuccess(res);
      } else {
        that.ToastWarning(res);
        console.log(res);
      }
    },
    // 所属区域选择框返回值然后赋值
    regionIdIdSelect(id) {
      this.Gridhead.regionId = id;
      console.log("regionIdIdSelect", id);
    },
    // finish(e) {
    //   console.log(e);
    //   this.Gridhead.cover = e[0].res.data.fileInfo.id;
    //   console.log(e[0].res.data.fileInfo.id);
    // },
  },
};
</script>
